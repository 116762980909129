import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { TableColumn } from '../models/table-column.model';
import { DataType } from '../enums/data-type.enum';
import { Table } from 'primeng/table';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'neo-matris-table',
  templateUrl: './matris-table.component.html',
  styleUrls: ['./matris-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatrisTableComponent implements OnInit {

  @Input() rows: any[] = []
  @Input() columns: TableColumn[] = []
  @Input() showLoadingColumn: boolean = false;
  @Input() tableHeader: string = ''
  @Input() isGroupHeader: boolean = false;
  @Input() totalRecords: number = 0
  @Input() loading: boolean = false;
  @Input() isFooterVisible = false;
  @Input() placeholder: string = ''
  @Input() showCaption: boolean = true;
  @Input() selectionMode: string = 'single';
  sortField: string = '';
  @Input() selectedRow: any;
  @Output() pageChange = new EventEmitter<any>()
  @Output() rowSelect = new EventEmitter<any>()
  @Output() rowUnselect = new EventEmitter<any>()
  @Output() tableDropdownChange = new EventEmitter<any>()
  @Output() historyData = new EventEmitter<any>()
  @Output() tableSearchButtonClick = new EventEmitter<any>()
  @Output() tableDateChange = new EventEmitter<any>()
  @Output() tableInputChange = new EventEmitter<any>()
  @Output() blurChange = new EventEmitter<any>()
  @Output() onKeydown = new EventEmitter<any>()
  @Output() deleteTableItem = new EventEmitter<any>()
  @Input() rowLevelEdit: boolean = false
  @Input() columnLevelEdit: boolean = !this.rowLevelEdit
  @Input() showPaginator: boolean = false;
  @Input() disableTable: boolean = false;
  @Input() showEmptyMessage: boolean = false;
  @Input() showTableSkeleton: boolean = false;
  @Input() skeletonRows: any = 20;
  @Input() showTable: boolean = true;
  @Input() reorderColumns: boolean = false;
  @Input() editTable: boolean = false;
  @Input() lockTable: boolean = false;
  @Input() lockTableForAddTeamMember: boolean = false;
  rowsCount: number = 10
  @Input() isTableBorderDisabled: boolean = false;
  @Output() addTeamMember = new EventEmitter<{ rowData: any, field: string, rowIndex: any }>();
  @Output() editMember = new EventEmitter<{ rowData: any, item: string; rowIndex: number; field: string }>();
  bindDate: any;
  @Input() first: number = 0
  collection = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  sizes!: any[];
  @ViewChild('calendarOverlay') calendarOverlay: OverlayPanel;
  @Input() showColumns: boolean = true;
  @Input() starredState: any;
  @Input() isTeamNew: boolean = false;
  @Output() dropdownFilterChange = new EventEmitter<{ event: any }>();
  @Output() sortStatus: EventEmitter<{ field: string, order: string }> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if (!this.isTeamNew) {
      this.initializeStarStateFromInput();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['starredState'] && !this.isTeamNew) {
      this.initializeStarStateFromInput();
    }
  }


  onHistoryLogoClick(rowIndex: number, field: string, rowData: any) {
    this.historyData.emit({ rowIndex, field, rowData })
  }

  onPageChange(event: any) {
    this.pageChange.emit(event)
  }

  onDropdownChange(event: any, field: any, row: any) {
    this.tableDropdownChange.emit({ event, field, row })
  }

  onSearchButtonClick(event: any, field: any, row: any) {
    this.tableSearchButtonClick.emit({ event, field, row })

  }

  deleteItem(rowData: any, field: string, itemIndex: number) {
    this.deleteTableItem.emit({ rowData, field, itemIndex })
  }


  onInputChange(event: any, field: any, row: any) {
    this.tableInputChange.emit({ event, field, row })
  }


  onDateChange(event: any, field: any, row: any) {
    this.tableDateChange.emit({ event, field, row })
  }


  handleBlur(event: any, field: any, row: any) {
    this.blurChange.emit({ event, field, row })
  }


  openEditDialog(rowData: any, item: string, rowIndex: number, field: string) {
    this.editMember.emit({ rowData, item, rowIndex, field });
  }

  onAddTeamMember(rowData: any, field: string, rowIndex: any): void {
    this.addTeamMember.emit({ rowData, field, rowIndex });

  }

  @Output() starToggled = new EventEmitter<any>();
  starStateMap: { [key: string]: boolean } = {};
  getStarKey(rowIndex: number, field: string): string {
    return `${rowIndex}-${field}`;
  }

  getStarClass(rowIndex: number, field: string, item: string): string {
    const key = `${rowIndex}-${field}-${item}`;
    return this.starStateMap[key] ? 'pi pi-star-fill text-yellow-500' : 'pi pi-star';
  }

  toggleStar(rowIndex: number, field: string, item: string, rowData: any): void {
    const key = `${rowIndex}-${field}-${item}`;
    const currentState = this.starStateMap[key];

    if (this.lockTable) {
      return;
    }

    Object.keys(this.starStateMap).forEach(starKey => {
      const [row] = starKey.split('-');
      if (+row === rowIndex) {
        this.starStateMap[starKey] = false;
      }
    });

    this.starStateMap[key] = !currentState;

    this.starToggled.emit({
      rowIndex: rowIndex,
      field: field,
      item: item,
      isStarred: this.starStateMap[key],
      rowData: rowData
    });
  }


  initializeStarStateFromInput(): void {
    if (this.starredState && this.starredState.length > 0) {
      this.starredState.forEach((starItem: { rowIndex: any; field: any; teamMember: any; }) => {
        const { rowIndex, field, teamMember } = starItem;
        const key = `${rowIndex}-${field}-${teamMember}`;
        this.starStateMap[key] = true;
      });
    }
  }

  getTextAlignmentClass(data: DataType) {
    switch (data) {
      case DataType.String:
        return "text-left";
        break;

      case DataType.Number:
        return "text-center";
        break;

      case DataType.Date:
        return "text-center";
        break;

      default:
        return "text-left";
        break;
    }
  }

  onDropdownChangeFilter(ev: any) {
    this.dropdownFilterChange.emit({ event: ev });
  }

  clear(table: Table) {
    table.clear();
  }
  sortOrder: number;
  getGlobalFilterFields(columns: any[]): string[] {
    return columns.map(col => col.field)
  }

  onRowSelect(event: any) {
    this.rowSelect.emit({ event: event, row: this.selectedRow });
  }

  onRowUnselect(event: any) {
    this.rowSelect.emit({ event: event, row: this.selectedRow });
    // Do not modify 
  }

  checkColumnType(columnType: any) {
    return columnType
  }

  sortState: { [key: string]: number } = {};

  onSortIconClick(field: string) {
    Object.keys(this.sortState).forEach(col => {
      if (col !== field) {
        this.sortState[col] = 0;
      }
    });
    if (!this.sortState[field] || this.sortState[field] === 0) {
      this.sortState[field] = 1; // ASC
    } else if (this.sortState[field] === 1) {
      this.sortState[field] = -1; // DESC
    } else {
      this.sortState[field] = 0; // No Sort
    }
    let orderText = '';
    if (this.sortState[field] === 1) {
      orderText = 'ASC';
    } else if (this.sortState[field] === -1) {
      orderText = 'DESC';
    } else {
      orderText = 'NONE';
    }
    this.sortStatus.emit({ field, order: orderText });
  }

  getSortIcon(field: string): string {
    const order = this.sortState[field] || 0;
    if (order === 1) {
      return 'pi pi-sort-amount-up-alt';  // ASC
    } else if (order === -1) {
      return 'pi pi-sort-amount-down-alt';  // DESC
    }
    return 'pi pi-sort-alt';  // No Sort
  }



}
